
import { Vue, Component, Watch } from 'vue-property-decorator';
import AproconHeader from '@aprocon-software/aprocon-header';
import AproconFooter from '@aprocon-software/aprocon-footer';

@Component({
    components: {
        AproconHeader,
        AproconFooter,
    },
})
export default class App extends Vue {

    private showServiceConfig: boolean = false;
    public created(): void {
        // wait until the router has resolved the query
        this.$router.onReady(this.init);
    }

    private init() {
        this.$store.dispatch('init', this.$route.query);
    }
    private changeService() {
        this.$router.push({ name: 'service', query: this.$route.query });
    }

    @Watch('$route.name')
    private showChangeService() : void {

        this.showServiceConfig = this.$route.name !== "service";
    }
}
