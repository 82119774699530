import axios from 'axios';
import { Error } from '@aprocon-software/aprocon-auth-util/src/types/index';
import type { ApiVersionResponseWrapper } from './ServiceModels';

export interface StatusServiceConfig {
    appId: () => string;
    serviceUrl: () => string;
}

export default class StatusService {

    private readonly config: StatusServiceConfig;

    constructor(config: StatusServiceConfig) {
        this.config = config;
    }

    public async loginAdmin(userName: string, password: string, serviceUrl:string): Promise<boolean> {
        const logonRequest = {
            AdminLogin: userName,
            AdminPass: password,
        };
        const response = await axios.post(`${serviceUrl}/Authentication/ValidateAdmin`, logonRequest);
        const responseData = response.data.ValidateAdmin_response;
        if (responseData?.ServiceErrors && responseData?.ServiceErrors.length > 0) {
            const serviceErrors: Error[] = responseData.ServiceErrors.map((error:any) => ({
                id: error.ERRORNUMBER,
                position: error.ERRORPOSITION,
                recordId: error.ERRORRECORDID,
                description: error.ERRORDESCRIPTION,
                recommendation: error.ERRORRECOMMENDATION,
            }));

            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            // eslint-disable-next-line no-throw-literal
            throw { serviceErrors };
        }
        console.info(responseData.Valid);
        return responseData.Valid;
    }
    /**
     * Get the api version
     */
    public async getApiVersion(serviceUrl?: string): Promise<string|undefined> {
        try {
            const request = {
                AppID: this.config.appId() || 'APRO.KASSENBUCH',
            };
            const response = await axios.post<ApiVersionResponseWrapper>(`${serviceUrl || this.config.serviceUrl()}/common/get_API_Version`, request);
            const responseData = response.data.get_API_Version_response;
            if (responseData.ServiceErrors && responseData.ServiceErrors.length > 0) {
                return undefined;
            }
            return `${responseData.Major}.${responseData.Minor}.${responseData.Build}`;
        } catch (e) {
            return undefined;
        }
    }

    public async serviceAvailable(serviceUrl?: string): Promise<boolean> {
        try {
            const request = {
                AppID: this.config.appId() || 'APRO.KASSENBUCH',
            };
            const response = await axios.post<ApiVersionResponseWrapper>(`${serviceUrl || this.config.serviceUrl()}/common/get_API_Version`, request);
            const responseData = response.data.get_API_Version_response;
            if (responseData.ServiceErrors && responseData.ServiceErrors.length > 0) {
                return true;
            }
            return true;
        } catch (e) {
            return false;
        }
    }
}
