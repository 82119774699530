import axios from 'axios';
import type {
    TblFilialenRequest,
    TblFilialenResponseWrapper,
} from './ServiceModels';

export interface Branch {
    id: string;
    name: string;
}

export interface Error {
    id: number;
    position: string;
    recordId: string;
    description: string;
    recommendation: string;
}

export interface BranchServiceConfig {
    appId: () => string;
    serviceUrl: () => string;
    sid: () => string;
    userId: () => string;
}

export default class BranchService {

    private readonly config: BranchServiceConfig;

    constructor(config: BranchServiceConfig) {
        this.config = config;
    }

    public async getBranches(userId:string|undefined = undefined): Promise<Branch[]> {
        const request: TblFilialenRequest = { UserID: userId || this.config.userId() };
        const response = await axios.post<TblFilialenResponseWrapper>(`${this.config.serviceUrl()}/Tbl_Filialen`, request);
        const responseData = response.data.Tbl_Filialen_response;
        if (responseData.ServiceErrors) {
            const serviceErrors: Error[] = responseData.ServiceErrors.map((error) => ({
                id: error.ERRORNUMBER,
                position: error.ERRORPOSITION,
                recordId: error.ERRORRECORDID,
                description: error.ERRORDESCRIPTION,
                recommendation: error.ERRORRECOMMENDATION,
            }));
            // eslint-disable-next-line no-throw-literal
            throw { serviceErrors };
        }
        return responseData.Tbl_Filialen
            .map((serviceObj) => ({ id: serviceObj.Nummer.toString(), name: serviceObj.Name }));
    }
}
