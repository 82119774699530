import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import type { Authorization } from '@aprocon-software/aprocon-auth-util';
import Services from '@/assets/js/services/Services';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<any>({
    storage: window.localStorage,
});

export default new Vuex.Store({
    state: {
        serviceUrl: null as string|null,
        appId: null as string|null,
        redirect: null as string|null,
        userId: null as string|null,
        branchId: null as string|null,
        sid: null as string|null,
        deviceId: null as string|null,
    },

    getters: {
        serviceUrl: (state) => () => state.serviceUrl || '',
        appId: (state) => () => state.appId || '',
        deviceId: (state) => () => state.deviceId || null,
        redirect: (state) => () => state.redirect || '',
        userId: (state) => () => state.userId || '',
        branchId: (state) => () => state.branchId || '',
        sid: (state) => () => state.sid || '',
        auth: (state) => () => ({ branchId: state.branchId, userId: state.userId, service: state.serviceUrl, sid: state.sid }),
        readyToRedirect: (state) => () => state.userId && state.sid && state.redirect,
    },

    mutations: {
        setServiceUrl: (state, payload: string) => {
            if (payload.length === 0) {
                return;
            }
            state.serviceUrl = payload;
            Services.serviceUrl = state.serviceUrl;
        },
        setAppId: (state, payload: string) => {
            state.appId = payload;
        },
        setDeviceId: (state, payload: string) => {
            state.deviceId = payload;
        },
        setRedirect: (state, payload: string) => {
            state.redirect = payload;
        },
        setUserId: (state, payload: string) => {
            state.userId = payload;
            Services.userId = state.userId;
        },
        setBranchId: (state, payload: string) => {
            state.branchId = payload;
            Services.branchId = state.branchId;
        },
        setSid: (state, payload: string) => {
            state.sid = payload;
            Services.sid = state.sid;
        },
    },

    actions: {
        init({ commit, getters }, query: any) {
            // copy data from query
            if (query.app) {
                commit('setAppId', query.app);
            }
            if (query.redirect) {
                commit('setRedirect', query.redirect);
            }
            if (query.service) {
                commit('setServiceUrl', query.service);
            }
            if (query.userId) {
                commit('setUserId', query.userId);
            }
            if (query.branchId) {
                commit('setBranchId', query.branchId);
            }
            // initialize services
            Services.serviceUrl = getters.serviceUrl();
            Services.sid = getters.sid();
            Services.userId = getters.userId();
            Services.branchId = getters.branchId();
            Services.appId = getters.appId();
        },
        login({ commit }, auth: Authorization) {
            commit('setUserId', auth.userId);
            commit('setBranchId', auth.branchId);
            commit('setSid', auth.sid);
            commit('setDeviceId', auth.deviceId);
        },
        logout({ commit }) {
            commit('setUserId', null);
            commit('setBranchId', null);
            commit('setSid', null);
            commit('setAppId', null);
        },
        clear({ commit }) {
            commit('setUserId', null);
            commit('setBranchId', null);
            commit('setSid', null);
            commit('setAppId', null);
            commit('setServiceUrl', null);
            commit('setRedirect', null);
        },
    },

    modules: {
    },

    plugins: [
        vuexLocal.plugin,
    ],
});
