import { AuthService, CloudflareService } from '@aprocon-software/aprocon-auth-util';
import UserService from '@/assets/js/services/users/UserService';
import StatusService from '@/assets/js/services/status/StatusService';
import BranchService from '@/assets/js/services/branches/BranchService';

export default class Services {

    public static appId: string|null = null;
    public static serviceUrl: string|null = null;
    public static sid: string|null = null;
    public static userId: string|null = null;
    public static branchId: string|null = null;

    private static config = {
        appId: () => Services.appId || '',
        serviceUrl: () => Services.serviceUrl || '',
        ip: () => Services.Cloudflare.getIp(),
        sid: () => Services.sid || '',
        userId: () => Services.userId || '',
        branchId: () => Services.branchId || '',
    }

    /**
     * Cloudflare Webservice to determine the users ip address
     */
    public static Cloudflare = new CloudflareService();

    /**
     * APRO.CON Webservice to handle authorization
     */
    public static Auth = new AuthService(Services.config);

    /**
     * APRO.CON Webservice for getting users
     */
    public static User = new UserService(Services.config);

    /**
     * APRO.CON Webservice for getting users
     */
    public static Branch = new BranchService(Services.config);

    /**
     * APRO.CON Webservice for getting the service status
     */
    public static Status = new StatusService(Services.config);
}
